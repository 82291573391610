import './SlideShowStyle.css';
import {useState} from 'react';
import {useEffect} from 'react';
import rat from './rat.png'


function SlideShow(){



    const colors = ["#00C49F", "#00C49F", "#FFBB28"];
    const [index, setIndex] = useState(0)
    const delay = 2500;
    useEffect(() => {
        setTimeout(
         () =>
            setIndex((prevIndex) =>
             prevIndex === colors.length - 1 ? 0 : prevIndex + 1
            ),
      delay
    );

    return () => {};
  }, [index]);
    return(
        <div className="slideshow">
             <div className="slideShowSlider" style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }} > 
                
                    {
                        colors.map((backgroundColor, index)=>( <div className="slide" key={index} src={backgroundColor}/>))
                        
                    }
                    
               
            </div>
                  <div className="slideshowDots">
                    {colors.map((e, idx) => (
                    <div key={idx} className={`slideshowDot${index === idx ? " active" : ""}`}             onClick={() => {
              setIndex(idx);
            }}></div>
        ))}
      </div>

        </div>
    );

}

export default SlideShow;
