import './summary.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons' 
import { faLocationDot, faPhone} from '@fortawesome/free-solid-svg-icons'

import rat from './rat.png'
const Summary =()=>{
   return(
       <div className='cont'>
          <div className="about">
              <h3>About us</h3><hr/>
              <span>Vincero link is a pest control company with close to 10 years of work experince we are dedicate to provide excellent service</span>
          </div>
          <div className="contact">
              
              <h3>Contact us</h3><hr/>
            <span className="homeAddress"><span className="back">< FontAwesomeIcon className="nono" icon={faLocationDot} /></span>9597 marksman street</span>
            <span className="city">olievenhoutbosch</span>
            <span  className="email"><span className="back"><FontAwesomeIcon className="nono" icon={faEnvelope} /></span>info@vincerolink.co.za</span>
            <span className="number"><span className="back"><FontAwesomeIcon className="nono" icon={faPhone} /></span>Call: 075 024 5957</span>
            <span className="whatsapp"><span className="back"><FontAwesomeIcon className="nono" icon={faWhatsapp} /></span>whatsApp: 075 024 5957</span>
            
          </div>
          <div className="email">
              <h3>Email us</h3><hr/>
           <form >
            <textarea/>
            <button>send</button>
           </form>
          </div>
           
       </div>
   );
}
export default Summary;