import './Main.css';
import small from './small.png'
import superJumbo from './superJumbo.png'
import smalldc from './smalldc.png'
import rat from './rat.png'
import licence from './licence.png'
const Main= ()=>{
    return(
        <div className="content">
            <div><h2>Here is some of our services</h2><hr/>
            <div className="services">
                <div className="service">
                <div className="wrap">
                <img src={small}/>
                <span>Ants</span>
                </div>
                <div className="wrap">
                <img src={superJumbo}/>
                <span>Cockroaches</span>
                </div>
                <div className="wrap">
                <img src={smalldc}/>
                <span>Wasps</span>
                </div>
                <div className="wrap">
                <img src={rat}/>
                <span>Rats</span>
                </div>
                </div>
            </div></div>
            
        </div>
    )
}
export default Main;