
import './App.css';
import Navbar from './Navbar';
import SlideShow from './SlideShow';
import Summary from './Summary';

import{ Carousel} from "./componets/Carousel";

import Footer from './Footer';
import {useState} from 'react';
import Main from './Main'
import slides from "./data/CarusoleData.json"

function App() {

  return (
    <div className=" content">
      <div className="NavigationBar">
         <Navbar/> 

        

      </div>
      <div className="slideShows">
           <SlideShow/> 
          {/* < Carousel data={slides}/> */}

      </div>
      <div className="mainPart">
      <Main/>
      </div>
      <div className="happyCustomer">

      </div>
      <div className="summary">
        <Summary/>

      </div>
      <div className="footer">
        <Footer/>

      </div>

    </div>
  );

}

export default App;
