import './NavbarStyle.css';
import logocolor from './logo-color.png'


const Navbar = ()=>{
    function open() {
        document.getElementById("navbar").style.width = "250px";
        console.log('im clicked')
    }
    return(
        <div className="holder">
            <h5><img className='logo' src={logocolor}/>vincero link</h5>
            
        <nav className='navbar'  id="navbar">
            <a href="" className="closebtn" onClick={open}>&times;</a>
            
            <div className='links'>
                <a href="/">Home</a>
                <a href="/create">About us</a>
                <a href="/">Contact</a>
                <a href="/create">Testimonie</a>
            </div>

        </nav>
        
        <span  className="open" onClick={open}>&#9776; opesn</span>

        </div>

    );

}
export default Navbar;